/**
 * Header Large
 */

const template = /*html*/ `
	<div>
		<v-toolbar dark fixed :scroll-off-screen="true" :class="{ 'scl-header-large__toolbar--top' : isAtTop }" class="scl-header-large__toolbar px-0" :height="getHeight" >
			<div class="scl-header-large__group"  ref="header">
			<scl-alert :api="{dismissible: true}" id="{12345}" class="scl-alert"></scl-alert>
				<v-container class="py-0">
					<v-layout row wrap justify-space-between>
						<v-flex xs2 class="scl-header-large__logo">
							<a href="/">
								<v-img
									src="/assets/img/Coates_140_Years_Website_V2_Coates140years125x60.svg"
									height="60"
									width="125"
									contain
								></v-img>
							</a>
						</v-flex>
						<v-flex xs10 pl-4>
							<v-layout row wrap>
								<v-flex d-flex align-center class="scl-header-large__top-links" text-xs-right>
									<a href="/about-us" target="_top">Contact us</a>
									<a href="/contact-us" target="_top" >Our Story</a>
									<a href="/contact-us" target="_top" >Media</a>
									<v-divider vertical class="mx-3" light></v-divider>
									<a href="/contact-us" target="_top" ><v-icon size="16" color="#FF4600" class="pr-2">far fa-map-marker-alt</v-icon> Find a Branch</a>
									<a href="/contact-us" target="_top" ><v-icon size="16" color="#FF4600" class="pr-2">far fa-phone-alt</v-icon> 13 15 52</a>
									<v-divider vertical class="mx-3" light></v-divider>
									<div class="scl-header-large__top-social">
										<a href="https://www.facebook.com/CoatesHire/" target="_blank" class="pr-3"><v-icon size="24" >fab fa-facebook</v-icon></a>
										<a href="https://www.instagram.com/coateshire/" target="_blank" class="pr-3"><v-icon size="24" >fab fa-instagram</v-icon></a>
										<a href="https://www.facebook.com/CoatesHire/" target="_blank"><v-icon size="24" >fab fa-linkedin</v-icon></a>
									</div>
									<v-divider vertical class="mx-3" light></v-divider>
									<a href="https://my.coateshire.com.au/" target="_blank">
										<v-icon size="16" color="#FF4600" class="pr-2">far fa-user-alt</v-icon>
										Coates Connect
									</a>
									<a href="https://hire-now.coateshire.com.au/" target="_blank" class="scl-header-large__nav-btn">
										<v-icon size="16" class="pr-2">far fa-shopping-cart</v-icon>
										Hire Now
									</a>
									</v-flex>
								<v-flex xs12>
									<v-divider light></v-divider>
								</v-flex>
								<v-flex xs12 d-flex align-center>
									<v-layout justify-space-between row wrap>
										<v-flex xs9 class="scl-header-large__menu-container">
											<template v-for="(navItems, index) in propApi.navigationItems">
												<v-menu
													attach=".scl-header-large__toolbar"
													:open-on-hover="!navItems.isSearch"
													:offset-y="!navItems.isSearch"
													:close-on-content-click="!navItems.isSearch"
													content-class="scl-header-large__menu"
													@input="menuItemActive[index] = $event"
													>
													<template v-slot:activator="{ on }">
														<v-btn
														href="#"
															:ripple="false"
															v-on="on"
															class="scl-header-large__toggle"
															:class="{ 'scl-header-large__toggle--is-active': menuItemActive[index] }"
														>
															<template v-if="navItems.isSearch">
																<v-icon class="scl-header-large__toggle-search-icon">fas fa-search</v-icon>
																<span v-if="$vuetify.breakpoint.lgAndUp">{{navItems.title}}</span>
																add search box
															</template>
															<template v-else>
																{{navItems.title}}
																<v-icon  color="#ff4600">{{propApi.navigationItemsIcon}}</v-icon>
															</template>
														</v-btn>
													</template>
													<v-container>
														<v-layout row>
															<v-flex xs12>
																<h5 class="pl-3">{{navItems.subTitle}}</h5>
																<v-list class="scl-header-large__menu-list py-2">
																	<v-list-tile v-for="(subItem, i) in navItems.navigationSubItems" :key="i" :href="subItem.url" :class="[...(subItem.css || []), 'scl-header-large__menu-item scl-header-large__menu-item--full flex xs3 px-3']">
																		<span>
																			{{subItem.title}}
																		</span>
																	</v-list-tile>
															</v-list>
															</v-flex>
														</v-layout>
													</v-container>
												</v-menu>
											</template>
										</v-flex>
										<v-flex xs3  d-flex align-center class="text-xs-right">
											<div class="scl-header-large__autocomplete-wrapper">
												<v-autocomplete
													v-model="model"
													:items="items"
													:search-input.sync="search"
													:filter="customFilter"
													placeholder="Try 'Scissor Lift'"
													class="scl-header-large__input scl-form__input py-0"
													:menu-props="{contentClass:'scl-header-large__autocomplete'}"
													hide-no-data
													hide-details
													item-text="Name"
													item-value="URL"
													append-icon="far fa-search"
													clear-icon="far fa-times"
													return-object
													clearable
													ref="autocompleteField"
													:loading="isLoading"
													cache-items
												></v-autocomplete>

											</div>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-container>
			</div>
		</v-toolbar>
	</div>
`;

export default template;
