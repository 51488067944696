/**
 * SclFooterListLarge
 */

// template
const template = `
	<div class="scl-component scl-footer-list-large">
		<v-container v-if="$vuetify.breakpoint.mdAndUp" class="scl-footer-list-large__container py-0">
			<v-layout row wrap class="scl-footer-list-large__wrapper">
				<v-flex md8 lg9 class="scl-footer-list-large__list-column">
					<v-layout row wrap class="scl-footer-list-large__list-row">
						<v-flex md3>
							<div class="scl-footer-list-large__title">Lorem ipsum</div>
							<v-list class="scl-footer-list-large__list">
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
							</v-list>
						</v-flex>
						<v-flex md3>
							<div class="scl-footer-list-large__title">Lorem ipsum</div>
							<v-list class="scl-footer-list-large__list">
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
							</v-list>
						</v-flex>
						<v-flex md3>
							<div class="scl-footer-list-large__title">Lorem ipsum</div>
							<v-list class="scl-footer-list-large__list">
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
							</v-list>
						</v-flex>
						<v-flex md3>
							<div class="scl-footer-list-large__title">Lorem ipsum</div>
							<v-list class="scl-footer-list-large__list">
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
								<v-list-tile href="#">
									Lorem ipsum dolor
								</v-list-tile>
							</v-list>
						</v-flex>
					</v-layout>
					<v-layout row wrap class="scl-footer-list-large__terms-container">
						<v-flex xs9 align-end d-flex>
							<div class="scl-footer__links">
								<v-list class="scl-footer__list">
									<div class="scl-footer__copyright">
										<v-list-tile class="scl-footer__list-item"><span>Copyright &copy; {{ new Date().getFullYear() }} Coates</span></v-list-tile>
									</div>
									<div class="scl-footer__list-container">
										<v-divider v-if="$vuetify.breakpoint.smAndUp" vertical></v-divider>
										<v-list-tile class="scl-footer__list-item" href="#" target="_blank">Terms of Use</v-list-tile>
										<v-divider vertical></v-divider>
										<v-list-tile class="scl-footer__list-item" href="#" target="_blank">Privacy Policy</v-list-tile>
										<v-divider vertical></v-divider>
										<v-list-tile class="scl-footer__list-item" href="#" target="_blank">Sitemap</v-list-tile>
									</div>
								</v-list>
							</div>
						</v-flex>
						<v-flex v-if="$vuetify.breakpoint.mdAndUp" xs3 align-end d-flex text-xs-right>
							<div>
								<v-btn :ripple="false" class="scl-footer-list-large__social-icon" flat icon href="#">
									<v-icon size="24">fab fa-linkedin</v-icon>
								</v-btn>
								<v-btn :ripple="false" class="scl-footer-list-large__social-icon" flat icon href="#">
									<v-icon size="24">fab fa-facebook</v-icon>
								</v-btn>
								<v-btn :ripple="false" class="scl-footer-list-large__social-icon" flat icon href="#">
									<v-icon  size="24">fab fa-instagram</v-icon>
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex md4 lg3>
					<v-layout row wrap class="scl-footer-list-large__brand-column">
						<v-flex xs12 class="scl-footer-list-large__callout">
							<div class="scl-footer-list-large__callout-content">
								<div class="scl-footer-list-large__callout-title pb-1">Give Us A Call</div>
								<div class="scl-footer-list-large__callout-call pl-3">
									<v-icon class="scl-footer-list-large__callout-icon" color="white" size="24">fas fa-phone-alt</v-icon>
									<span class="scl-footer-list-large__callout-number">13 15 52</span>
								</div>
								<scl-button inline-template :api="contactBtnCta">
									<v-btn depressed class="scl-component scl-footer-list-large__contact-btn scl-button scl-button--small scl-button--dark"
										:href="propApi.href"
										:outline="propApi.outline"
										:target="propApi.target"
										:class="{'scl-button--outline' : propApi.outline }"
									>
										{{ propApi.copy }} <v-icon v-if="propApi.icon" class="scl-button__icon">{{ propApi.icon }}</v-icon>
									</v-btn>
								</scl-button>
							</div>
						</v-flex>
						<v-flex xs12 class="scl-footer-list-large__callout scl-footer-list-large__callout--bottom">
							<a href="/">
								<v-img
									src="/assets/img/Coates_140_Years_Website_V2_Coates140years125x60.svg"
									height="60"
									width="125"
									contain
								></v-img>
							</a>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>


		</v-container>

		<!-- mobile only accordions -->
		<div v-if="$vuetify.breakpoint.smAndDown">
			<v-container>
				<v-layout row wrap class="scl-footer-list-large__accordion-wrap">
					<v-flex xs12>
						<v-expansion-panel>
							<v-expansion-panel-content lazy>
								<template v-slot:header>
									<div class="scl-footer-list-large__title">Nav Item Display Text</div>
								</template>
								<v-card>
									<v-list class="scl-footer-list-large__list">
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
									</v-list>
								</v-card>
							</v-expansion-panel-content>
							<v-expansion-panel-content lazy>
								<template v-slot:header>
									<div class="scl-footer-list-large__title">Nav Item Display Text</div>
								</template>
								<v-card>
									<v-list class="scl-footer-list-large__list">
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
									</v-list>
								</v-card>
							</v-expansion-panel-content>
							<v-expansion-panel-content lazy>
								<template v-slot:header>
									<div class="scl-footer-list-large__title">Nav Item Display Text</div>
								</template>
								<v-card>
									<v-list class="scl-footer-list-large__list">
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
									</v-list>
								</v-card>
							</v-expansion-panel-content>
							<v-expansion-panel-content lazy>
								<template v-slot:header>
									<div class="scl-footer-list-large__title">Nav Item Display Text</div>
								</template>
								<v-card>
									<v-list class="scl-footer-list-large__list">
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
										<v-list-tile href="#">
											Nav Children Display Text
										</v-list-tile>
									</v-list>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-flex>
				</v-layout>
			</v-container>

			<div class="scl-footer-list-large__callout">
				<v-container class="scl-footer-list-large__container">
					<v-layout row wrap class="scl-footer-list-large__callout-wrap">
						<v-flex xs12>
							<div class="scl-footer-list-large__callout-title pb-1">Give us a call</div>
							<div class="scl-footer-list-large__callout-call pl-3">
								<v-icon class="scl-footer-list-large__callout-icon" color="white" size="24">fas fa-phone-alt</v-icon>
								<a href="tel:131552" class="scl-footer-list-large__callout-number">13 15 52</a>
							</div>
							<scl-button :api="{ href: 'http://www.example.com/', outline: true, copy: 'Contact Us' }" class="scl-component scl-footer-list-large__contact-btn scl-button scl-button--small scl-button--dark"></scl-button>
						</v-flex>
					</v-layout>
				</v-container>
			</div>

			<div class=" scl-footer-list-large__callout scl-footer-list-large__callout--bottom">
				<v-container class="scl-footer-list-large__container">
					<v-layout row wrap class="scl-footer-list-large__callout-wrap">
						<v-flex xs12>
							<a href="#">
								<v-img
									src="/assets/img/Coates_140_Years_Website_V2_Coates140years125x60.svg"
									height="60"
									width="125"
									contain
								></v-img>
							</a>
						</v-flex>
					</v-layout>
				</v-container>
			</div>

			<div class="scl-footer-list-large__terms-container">
				<v-container>
					<v-layout row wrap>
						<v-flex xs12>
							<div class="scl-footer__copyright">
								<v-list-tile class="scl-footer__list-item"><span>Copyright &copy; {{ new Date().getFullYear() }} Coates Hire</span></v-list-tile>
							</div>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12>
							<div class="scl-footer-list-large__list-container">
								<v-list-tile class="scl-footer-list-large__list-item" href="#" target="_blank">Terms of Use</v-list-tile>
								<v-divider vertical></v-divider>
								<v-list-tile class="scl-footer-list-large__list-item" href="#" target="_blank">Privacy Policy</v-list-tile>
								<v-divider vertical></v-divider>
								<v-list-tile class="scl-footer-list-large__list-item" href="#" target="_blank">Sitemap</v-list-tile>
							</div>
						</v-flex>
					</v-layout>
				</v-container>
			</div>
		</div>

	</div>
`;

// export template
export default template;
